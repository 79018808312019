.itinerary-section-heading-button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.itinerary-search-inner-section {
    border-radius: 10px;
    border: 1px solid lightgrey;
    margin-top: 2rem;
}

.itinerary-search-inner-section > *:not(:last-child) {
    padding-bottom: 0.25rem;
}

.itinerary-search-inner-section > *:last-child {
    padding-bottom: 1rem;
}

.itinerary-search-inner-section > * {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.75rem;
}

@media only screen and (min-width: 768px) {
    .itinerary-search-section {
        height: 26vh;
    }

    .itinerary-search-inner-section {
        height: 17vh;
    }

    .itinerary-section-heading-button {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .itinerary-search-inner-section > *:not(:last-child) {
        border-right: 1px solid lightgrey;
    }

    .itinerary-search-inner-section > * {
        padding-top: 1.5rem;
    }
}

@media only screen and (min-width: 576px) {
    .itinerary-search-inner-section > *:nth-child(2n+1) {
        border-right: 1px solid lightgrey;
    }
}

@media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    .itinerary-search-section {
        height: fit-content;
    }

    .itinerary-search-inner-section {
        height: fit-content;
    }

    .itinerary-search-inner-section > .col-md-2 {
        width: 33.33333%;
    }

    .itinerary-search-inner-section > *:nth-child(3n+3) {
        border: none;
    }
}